import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorPage from './pages/error';
import Home from './routes/home';
import About from './routes/about';
import Location from './routes/location';
import ComingSoon from './pages/comingsoon';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0A3161',
      light: '#bcc6d7'
    },
    lightPrimary: {
      main: '#bcc6d7'
    },
    secondary: {
      main: '#B31942',
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "home",
        element: <Home />
      },
      {
        path: "about",
        element: <About />
      },
      {
        path: "location",
        element: <Location />
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

const domain = window.location.hostname.toLowerCase();
const shouldShowComingSoon = domain.includes('anderson-avionics') 
  && !domain.includes('test.') 
  && ((new Date()) > (new Date(2024,11,25)));

if (shouldShowComingSoon){
  root.render(
    <React.StrictMode>
      <ComingSoon />
    </React.StrictMode>
  );
}else{
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
